import React from 'react'
import './style.scss'
import '../../assets/fonts/fontello-771c82e0/css/fontello.css'

class Links extends React.Component {
  render() {
    const author = this.props.data
    const links = {
      telegram: author.telegram,
      twitter: author.twitter,
      github: author.github,
      vk: author.vk,
      rss: author.rss,
      email: author.email,
    }

    return (
      <div className="links">
        <a
          href={`https://www.twitter.com/${links.twitter}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
        <a
          href={`https://www.github.com/${links.github}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <a href={`mailto:${links.email}`}>Email</a>
        <a href={links.rss} target="_blank" rel="noopener noreferrer">
          RSS
        </a>
      </div>
    )
  }
}

export default Links
