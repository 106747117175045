import React from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const IntroText = styled.p`
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
`

const EmailInput = styled.input`
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: block;
  width: 80%;
  outline: none;
`

const Button = styled.button`
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 3px;
  outline: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  background-color: #5d93ff;
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  border: none;
  transition: background-color 0.25s ease;
  &:hover {
    background-color: #f7a046;
  }
`

class SignUpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {email: '', subscribed: false}

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = event => {
    this.setState({email: event.target.value})
  }
  handleSubmit = event => {
    event.preventDefault()
    addToMailchimp(this.state.email)
      .then(data => {
        this.setState({subscribed: true})
      })
  }
  render() {
    const subscribed = this.state.subscribed
    if (!subscribed) {
      return (
        <form onSubmit={this.handleSubmit}>
          <IntroText>I write about anything Android! If you want to be kept up-to-date, make sure to sign up.</IntroText>
          <EmailInput type="email" value={this.state.email} onChange={this.handleChange} placeholder="example@domain.com"/>
          <Button>SUBMIT</Button>
        </form>
      )
    } else {
      return (
        <IntroText>Subscribed! Thanks for reading my blog.</IntroText>
      )
    }
  }
}

export default SignUpForm
